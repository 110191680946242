import { Menu, Badge, Button, Skeleton } from "antd";
import {
	AppstoreOutlined,
	DollarOutlined,
	UserOutlined,
	WalletOutlined,
	FileDoneOutlined,
	AreaChartOutlined,
	ShoppingOutlined,
	ReadOutlined,
	CarryOutOutlined,
	TeamOutlined,
	ImportOutlined,
	ExportOutlined,
	FileSyncOutlined,
	BuildOutlined,
	FileTextOutlined,
} from "@ant-design/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import CertificatePurchase from "components/CertificatePurchase";
import Insurance from "components/Insurance";

const MenuRoutes = ({ handleClick, keys }) => {
	const { data } = useQuery(
		gql`
		{
			UserBadges {
				dasInstallments
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const userGlobalInfo = useQuery(
		gql`
		{
			UserGlobalPlan {
				routes
			}
		}
	`,
		{ fetchPolicy: "no-cache" }
	);

	const [saveLead] = useMutation(gql`
		mutation SaveLead($input: LeadInput!)	 {
			SaveLead(input: $input)
		}
	`);

	const links = useQuery(
		gql`
		query GetAvailableLinks($app: String!) {
			GetAvailableLinks(app: $app)
		}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				app: import.meta.env.VITE_APP_THEME || "openmei",
			},
		}
	);

	const routeAvailable = (route) => {

		route = route.split('?')[0];

		if (userGlobalInfo?.data?.UserGlobalPlan?.routes?.length) {
			if (userGlobalInfo?.data?.UserGlobalPlan?.routes.indexOf(route) === -1) {
				return false;
			}
		}
		return true;
	};

	const MenuItem = (props) => {
		if (!routeAvailable(props.eventKey)) {
			return null;
		}
		return <Menu.Item {...props} />;
	}

	const SubMenu = (props) => {
		let availableKeys = props.keys.filter((key) => routeAvailable(key));
		if(availableKeys.length === 0) return null;
		return <Menu.SubMenu key={props.eventKey}  {...props}>{props.children}</Menu.SubMenu>;
	}



	if (userGlobalInfo.loading)
		return (
			<div
				className="menu-wrapper"
				style={{
					padding: 10,
				}}
			>
				{Array(4)
					.fill(0)
					.map((_, index) => (
						<Skeleton key={index} active />
					))}
			</div>
		);

	return (
		<>
			<div className="menu-wrapper">
				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					openKeys={["/reps", "/invoices-wrap"]}
					mode="inline"
					expandIcon={<span></span>}
				>
					<MenuItem icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</MenuItem>

					{/*
                 <MenuItem icon={<CalendarOutlined />} key={'/schedules'}>
                     Agenda
                 </MenuItem>
                 */}

					{/*
                <MenuItem icon={<DollarOutlined />} key={'/sales'}>
                    Vendas
                </MenuItem>
                */}

					<MenuItem icon={<DollarOutlined />} key={"/cash-launch"}>
						Caixa
					</MenuItem>
					<MenuItem icon={<ExportOutlined />} key={"/bills/expenses"}>
						Contas a Pagar
					</MenuItem>
					<MenuItem icon={<ImportOutlined />} key={"/bills/income"}>
						Contas a Receber
					</MenuItem>
					<MenuItem icon={<TeamOutlined />} key={"/clients"}>
						Clientes
					</MenuItem>
					<MenuItem icon={<BuildOutlined />} key={"/suppliers"}>
						Fornecedores
					</MenuItem>
					<MenuItem icon={<ShoppingOutlined />} key={"/products"}>
						Meus Produtos
					</MenuItem>

					<MenuItem icon={<ReadOutlined />} key={"/stock"}>
						Controle de Estoque
					</MenuItem>
					<MenuItem icon={<WalletOutlined />} key={"/das"}>
						DAS
					</MenuItem>
					<MenuItem icon={<WalletOutlined />} key={"/das-installment"}>
						DAS de Parcelamento
						{data?.UserBadges?.dasInstallments ? (
							<Badge
								count={data?.UserBadges?.dasInstallments}
								style={{ marginLeft: 10, marginTop: -2 }}
							/>
						) : null}
					</MenuItem>

					<MenuItem icon={<FileSyncOutlined />} key={"/documents"}>
						Docs. Enviados/Recebidos
					</MenuItem>

					<MenuItem icon={<WalletOutlined />} key={"/active-debts"}>
						Dívida ativa
					</MenuItem>

					<SubMenu
						icon={<FileTextOutlined />}
						title="Notas Fiscais"
						key="/invoices-wrap"
						keys={["/invoices"]}
					>
						<MenuItem key={"/invoices?new=true"}>Emitir Nota Fiscal</MenuItem>
						<MenuItem key={"/invoices"}>Notas Emitidas</MenuItem>
					</SubMenu>

					<MenuItem icon={<FileDoneOutlined />} key={"/receipts"}>
						Recibos
					</MenuItem>

					<SubMenu
						icon={<AreaChartOutlined />}
						title="Relatórios"
						key="/reps"
						keys={["/reports/income", "/reports/expenses"]}
					>
						<MenuItem key={"/reports/income"}>
							Relatório de Faturamento
						</MenuItem>
						<MenuItem key={"/reports/expenses"}>
							Relatório de Despesa
						</MenuItem>
					</SubMenu>

					<MenuItem icon={<CarryOutOutlined />} key={"/services"}>
						Serviços
					</MenuItem>

					<MenuItem icon={<CarryOutOutlined />} key={"/tutorials"}>
						Tutoriais
					</MenuItem>

					<MenuItem icon={<CarryOutOutlined />} key={"/documents/important"}>
						Documentos Importantes
					</MenuItem>

					<MenuItem icon={<UserOutlined />} key={"/profile"}>
						Meu Perfil
					</MenuItem>
				</Menu>

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("certificate") > -1 ? (
					<CertificatePurchase />
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("santander") > -1 ? (
					<div
						style={{
							padding: 10,
							paddingTop: 0,
						}}
					>
						{import.meta.env.VITE_APP_THEME === "simei" ? (
							<Button
								type="primary"
								style={{
									width: "100%",
									color: "white",
									backgroundColor: "#324396",
									borderColor: "#324396",
								}}
								onClick={() => {
									saveLead({
										variables: {
											input: {
												source: "r2bank",
											},
										},
									});

									let url = "https://app.r2bank.com.br/login";

									window.open(url);
								}}
							>
								R2 BANK
							</Button>
						) : (
							<Button
								type="primary"
								style={{
									width: "100%",
									color: "white",
									backgroundColor: "#cc0100",
									borderColor: "#cc0100",
								}}
								onClick={() => {
									saveLead({
										variables: {
											input: {
												source: "santander",
											},
										},
									});

									let url =
										"https://www.santander.com.br/conta-empresarial?id=5e0015c64068c090fcb46595205df9b7da21ecfc508d3b96e57e12c8283a4977";

									if (import.meta.VITE_APP_THEME === "simei") {
										url = "";
									}

									window.open(url);
								}}
							>
								Santander MEI+
							</Button>
						)}
					</div>
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("insurance") > -1 ? (
					<Insurance />
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("inove") > -1 ? (
					<div
						style={{
							padding: 10,
							paddingTop: 0,
						}}
					>
						<Button
							type="primary"
							style={{
								width: "100%",
								color: "white",
								backgroundColor: "#f48120",
								borderColor: "#f48120",
							}}
							onClick={() => {
								window.open("http://www.inoveclick.com.br/");
								saveLead({
									variables: {
										input: {
											source: "inoveclick",
										},
									},
								});
							}}
						>
							Marketing Digital
						</Button>
					</div>
				) : null}
			</div>
		</>
	);
};

export default MenuRoutes;
